import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
// import eventBus from "@/app/app-event-bus.mjs";
import LoLMatchTile from "@/game-lol/components/MatchTile.jsx";
import MatchTileHeader from "@/game-lol/components/MatchTileHeader.jsx";
import ChampionsStats from "@/game-lol/components/ProfileChampionStats.jsx";
import ProfileHeader from "@/game-lol/components/ProfileHeader.jsx";
import ProfileLPHistory from "@/game-lol/components/ProfileLPHistory.jsx";
import RecentlyPlayedWith from "@/game-lol/components/ProfileRecentlyPlayedWith.jsx";
import { LoLWinStreakBadge } from "@/game-lol/components/WinStreakBadges.jsx";
import { ARENA_RANK_SYMBOL_TO_STR } from "@/game-lol/constants/arena-queue-constants.mjs";
import {
  QUEUE_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  REGION_LIST,
} from "@/game-lol/constants/constants.mjs";
import {
  AramChampions,
  AramChampionTooltip,
  PageWrapper,
} from "@/game-lol/ProfileOverview.style.jsx";
import { kdaColor } from "@/game-lol/utils/kda-color.mjs";
import {
  convertRankFromRomanToNumber,
  getRankColor,
} from "@/game-lol/utils/rank-utils.mjs";
import { shouldShowPromo } from "@/game-lol/utils/season-review.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol, {
  QUEUE_SYMBOL_TO_OBJECT,
} from "@/game-lol/utils/symbol-queue.mjs";
import useSummonerRanks from "@/game-lol/utils/use-summoner-ranks.mjs";
import {
  getARAMStats,
  getDerivedId,
  getDerivedQueue,
  getDerivedRiotID,
  getStaticData,
  getWinRateColor,
  isAbandonedMatch,
  isPBE,
} from "@/game-lol/utils/util.mjs";
import Container from "@/shared/ContentContainer.jsx";
import ErrorComponent from "@/shared/ErrorComponent.jsx";
import { useReRender } from "@/shared/EventedRender.jsx";
import SharedProfile, {
  MatchList as SharedMatchList,
  MatchTile as SharedMatchTile,
  UnknownPlayerHeader,
} from "@/shared/Profile.jsx";
import {
  ProfileColumn,
  ProfileLayout,
  ProfileMatchlist,
} from "@/shared/Profile.style.jsx";
import ProfileEmpty from "@/shared/ProfileEmpty.jsx";
import ProfileRank from "@/shared/ProfileRank.jsx";
import clone from "@/util/clone.mjs";
import { devError } from "@/util/dev.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import unionBy from "@/util/union-by.mjs";
import { sendInteractionEvent } from "@/util/use-interaction-event.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Profile() {
  const { t } = useTranslation();
  const {
    searchParams,
    parameters: [region, name],
  } = useRoute((prev, next) => prev?.currentPath === next.currentPath);
  const state = useSnapshot(readState);
  const [queueGQL, _setQueueGQL] = useState(searchParams.get("queue") || "all");

  const queue = QueueSymbol(queueGQL);

  const derivedId = name && getDerivedId(region, name);
  const isDerivedId = Boolean(derivedId);
  const profile = state.lol.profiles[derivedId];
  const profileError = profile instanceof Error ? profile : null;

  const riotAccount = profile?.riotAccount;
  if (riotAccount?.gameName) {
    const { gameName, tagLine } = profile.riotAccount;

    try {
      const derivedRiotID = getDerivedRiotID(gameName, tagLine);
      if (derivedRiotID !== name) {
        updateRoute(`/lol/profile/${region}/${derivedRiotID}`);
      }
    } catch (e) {
      devError(
        "Failed to derive Riot ID from riotAccount",
        e,
        profile.riotAccount,
      );
    }
  }

  if (profileError) {
    const errorDescription = t(
      "common:error.summonerNotFound",
      "We're unable to find the summoner name in the region specified. Make sure you're not entering your login username, and check the region.",
    );
    return (
      <PageWrapper>
        <ErrorComponent description={errorDescription} />
      </PageWrapper>
    );
  }

  if (isPBE(region)) {
    return (
      <ProfileLayout>
        <ProfileHeader name={name} region={region} />
        <Container>
          <Card
            style={{ display: "grid", placeContent: "center", minHeight: 320 }}
          >
            <h1>
              {t(
                "lol:pbeProfilesNotSupported",
                "Blitz Profiles are not supported on the PBE",
              )}
            </h1>
          </Card>
        </Container>
      </ProfileLayout>
    );
  }

  return (
    <>
      <ProfileLayout>
        {!profileError ? (
          <ProfileHeader name={name} region={region} />
        ) : (
          <UnknownPlayerHeader />
        )}
        {isDerivedId ? (
          <Container>
            <SharedProfile>
              <ProfileColumn className="sidebar">
                <ProfileRanks name={name} region={region} />
                <ChampionsStats name={name} region={region} queue={queue} />
                <RecentlyPlayedWith name={name} region={region} />
              </ProfileColumn>
              <ProfileColumn className="main">
                <LoLMatchColumn name={name} region={region} />
              </ProfileColumn>
            </SharedProfile>
          </Container>
        ) : (
          <ProfileEmpty />
        )}
      </ProfileLayout>
      {/* <SeasonReviewPromoModal name={name} region={region} /> */}
    </>
  );
}

const SECONDARY_QUEUES = [QUEUE_SYMBOLS.rankedFlex, QUEUE_SYMBOLS.arena];

function ProfileRanks({ name, region }) {
  const { t } = useTranslation();
  const {
    lol: { profiles, playerChampionStats },
  } = useSnapshot(readState);
  const derivedId = getDerivedId(region, name);
  const profile = profiles[derivedId];
  const playerRanks = useSummonerRanks(derivedId);
  const champions = getStaticData("champions");

  if (!profile) {
    return (
      <>
        <Card loading style={{ height: 308 }} />
        <Card loading style={{ height: IS_APP ? 89 : 44 }} />
      </>
    );
  }

  const soloQueue = QUEUE_SYMBOL_TO_OBJECT[QUEUE_SYMBOLS.rankedSoloDuo];
  const SoloQueueIcon = soloQueue?.icon?.default;
  const soloQueueStats = playerRanks.find(
    (rank) => QueueSymbol(rank.queue) === QUEUE_SYMBOLS.rankedSoloDuo,
  );

  const aram = QUEUE_SYMBOL_TO_OBJECT[QUEUE_SYMBOLS.aram];
  const ARAMIcon = aram?.icon?.default;

  const {
    wins = 0,
    losses = 0,
    leaguePoints = 0,
    tier,
    rank,
  } = soloQueueStats || {};
  const soloQueueTier = RANK_SYMBOL_TO_STR[tier];
  const soloQueueTierName = soloQueueTier
    ? [soloQueueTier.t.name, soloQueueTier.t.fallback]
    : ["common:rank.unranked", "Unranked"];
  const tierString = t([...soloQueueTierName]);
  const division = convertRankFromRomanToNumber(rank);
  const graphPoints = (profile?.ranks || []).filter(
    (insertion) => insertion.queue === QUEUE_SYMBOLS.rankedSoloDuo,
  );

  const aramData =
    playerChampionStats?.[getDerivedQueue(derivedId, QUEUE_SYMBOLS.aram)];
  const aramStats = getARAMStats(playerRanks, aramData);

  // List of other queues
  const secondaryQueues = SECONDARY_QUEUES.map((queueSymbol) => {
    const queueInfo = QUEUE_SYMBOL_TO_OBJECT[queueSymbol];
    if (!queueInfo) return null;

    const isArena = queueSymbol === QUEUE_SYMBOLS.arena;
    const queueName = queueInfo.label;
    const QueueIcon = queueInfo?.icon?.default;
    const rankInfo = playerRanks.find((rank) => rank.queue === queueSymbol);
    const {
      wins = 0,
      losses = 0,
      leaguePoints = 0,
      tier,
      rank,
    } = rankInfo || {};
    const games = wins + losses;
    const winrate = wins / (games || 1);

    const tierSymbol = isArena
      ? ARENA_RANK_SYMBOL_TO_STR[tier]
      : RANK_SYMBOL_TO_STR[tier];

    const tierName = tierSymbol
      ? [tierSymbol.t.name, tierSymbol.t.fallback]
      : ["common:rank.unranked", "Unranked"];
    const tierString = t([...tierName]);
    const division = convertRankFromRomanToNumber(rank);

    const winrateColor = games
      ? getWinRateColor(winrate * 100)
      : "var(--shade2)";

    // BE does not yet support Arena and we shouldnt show "unranked"
    if (!IS_APP && isArena) return null;

    return {
      queueIcon: QueueIcon && <QueueIcon />,
      label: t([...queueName]),
      rankName: isArena
        ? (leaguePoints && `${tierString} ${leaguePoints}`) || tierString
        : (tierString && division && `${tierString} ${division}`) || tierString,
      points: leaguePoints,
      percentFill: isArena ? winrate : leaguePoints / 100,
      wins,
      losses,
      summaryColor: getRankColor(tierSymbol?.key) || "var(--shade2)",
      textTitleColor: winrateColor,
      barColor: winrateColor,
      titleOverride:
        isArena &&
        games &&
        t("common:percentTopN", "{{winRate}} Top {{n}}", {
          winRate: winrate.toLocaleString(getLocale(), {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          n: 4,
        }),
      onExpand: () => {
        sendInteractionEvent("profile-sub-rank-expand");
      },
    };
  }).filter(Boolean);

  if (aramStats.isARAMPlayer) {
    const aramChampions = clone(aramData || [])
      .sort((a, b) => b.gameCount - a.gameCount)
      .slice(0, 6);
    const mostPlayed = aramChampions[0];

    const otherQueues = [
      {
        queueIcon: SoloQueueIcon && <SoloQueueIcon />,
        label: t(...soloQueue.label),
        rankName:
          (tierString && division && `${tierString} ${division}`) || tierString,
        points: leaguePoints,
        percentFill: wins / (wins + losses),
        wins,
        losses,
        summaryColor: soloQueueTier
          ? getRankColor(soloQueueTier.key)
          : "var(--shade2)",
        textTitleColor: getWinRateColor(wins / (wins + losses)),
        barColor: getWinRateColor(wins / (wins + losses)),
        onExpand: () => {
          sendInteractionEvent("profile-sub-rank-expand");
        },
      },
      ...secondaryQueues,
    ];

    return (
      <>
        <ProfileRank
          title={t(...aram.label)}
          image={`${appURLs.CDN}/blitz/lol/misc/lcu_map_howling_abyss_v3.webp`}
          name={t("common:thisSeason", "This Season")}
          winStreakBadgeComponent={null}
          points={null}
          percentFill={aramStats.stats.wins / aramStats.stats.games}
          color={"var(--blue)"}
          wins={aramStats.stats.wins}
          losses={aramStats.stats.losses}
          belowTitle={t("common:mostPlayed", "Most Played")}
          below={
            <div className={AramChampions()}>
              {aramChampions.map((champion) => {
                const key = champions.keys[champion.championId];
                const staticChampion = champions[key];
                const winrate = champion.basicStats.wins / champion.gameCount;
                const kda =
                  (champion.basicStats.kills + champion.basicStats.assists) /
                  (champion.basicStats.deaths || 1);

                return (
                  <div
                    key={champion.championId}
                    data-tip={renderToString(
                      <div className={AramChampionTooltip()}>
                        {staticChampion ? (
                          <span className="type-subtitle--bold">
                            {staticChampion.name}
                          </span>
                        ) : null}
                        <div className="stat">
                          <span className="type-caption--semi shade2">
                            {t("common:stats.matches", "Matches")}
                          </span>
                          <span className="type-caption--bold">
                            {champion.gameCount}
                          </span>
                        </div>
                        <div className="stat">
                          <span className="type-caption--semi shade2">
                            {t("lol:winRate", "Win Rate")}
                          </span>
                          <span
                            className="type-caption--bold"
                            style={{ color: getWinRateColor(winrate * 100) }}
                          >
                            {winrate.toLocaleString(getLocale(), {
                              style: "percent",
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            })}
                          </span>
                        </div>
                        <div className="stat">
                          <span className="type-caption--semi shade2">
                            {t("common:stats.kda", "KDA")}
                          </span>
                          <span
                            className="type-caption--bold"
                            style={{ color: kdaColor(kda) }}
                          >
                            {kda.toLocaleString(getLocale(), {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            })}
                          </span>
                        </div>
                      </div>,
                    )}
                    style={{
                      "--bar-fill": champion.gameCount / mostPlayed.gameCount,
                      "--wr-fill": winrate,
                      "--wr-color": getWinRateColor(winrate * 100),
                    }}
                  >
                    <div className="bar">
                      <div className="fill">
                        <div className="wins"></div>
                      </div>
                    </div>
                    <ChampionImg size={26} championId={champion.championId} />
                  </div>
                );
              })}
            </div>
          }
        />
        <ProfileRank.Accordion ranksList={otherQueues} />
      </>
    );
  }

  if (aramStats.stats.games) {
    secondaryQueues.push({
      queueIcon: ARAMIcon && <ARAMIcon />,
      label: t(...aram.label),
      rankName: t("lol:countGame", "{{count, number}} Games", {
        count: aramStats.stats.games,
      }),
      percentFill: aramStats.stats.wins / aramStats.stats.games,
      wins: aramStats.stats.wins,
      losses: aramStats.stats.losses,
      summaryColor: "var(--shade2)",
      textTitleColor: "var(--shade2)",
      barColor: getWinRateColor(
        aramStats.stats.wins / (aramStats.stats.wins + aramStats.stats.losses),
      ),
      onExpand: () => {
        sendInteractionEvent("profile-sub-rank-expand");
      },
    });
  }

  return (
    <>
      <ProfileRank
        title={t(...soloQueue.label)}
        image={Static.getRankImage(soloQueueTier?.key || "unranked", 80)}
        name={
          (tierString && division && `${tierString} ${division}`) || tierString
        }
        winStreakBadgeComponent={
          <LoLWinStreakBadge name={name} region={region} />
        }
        points={t("lol:leaguePoints", "{{points}} LP", {
          points: leaguePoints,
        })}
        percentFill={leaguePoints / 100}
        color={
          soloQueueTier ? getRankColor(soloQueueTier.key) : "var(--shade2)"
        }
        wins={wins}
        losses={losses}
        belowTitle={t("lol:lpGraph", "LP Graph")}
        below={
          graphPoints.length >= 4 ? (
            <ProfileLPHistory
              name={name}
              region={region}
              queue={QUEUE_SYMBOLS.rankedSoloDuo}
            />
          ) : null
        }
      />
      <ProfileRank.Accordion ranksList={secondaryQueues} />
    </>
  );
}

function LoLMatchColumn({ region, name, role }) {
  const {
    lol: { profiles, matches, matchlists, localMatchlists },
  } = useSnapshot(readState);
  const derivedId = getDerivedId(region, name);
  const profile = profiles[derivedId];
  const champions = getStaticData("champions");
  const matchLinkPrefix = `/lol/match/${region}/${name}`;
  useReRender();

  const getMatchDetail = (id) => {
    const match = matches[id];
    return {
      match,
    };
  };

  const localMatchList = localMatchlists?.[derivedId] || [];
  const userMatchlists = matchlists?.[derivedId];
  const sanitizedMatchList =
    userMatchlists && !(userMatchlists instanceof Error) ? userMatchlists : [];
  const matchList = sanitizedMatchList;
  const mergedMatchlist = unionBy(localMatchList, matchList, "id");
  const filteredMatchlist = mergedMatchlist.filter((matchlistItem) => {
    const { match } = getMatchDetail(matchlistItem.id);
    return !isAbandonedMatch(match);
  });

  // In-place operation
  filteredMatchlist.sort((a, b) => {
    const { match: matchA } = getMatchDetail(a.id);
    const { match: matchB } = getMatchDetail(b.id);

    if (matchA && matchB) {
      const { gameCreation: creationA } = matchA;
      const { gameCreation: creationB } = matchB;

      return creationB - creationA;
    }
    return 0;
  });

  const loadedMatches = filteredMatchlist.map((m) => ({
    ...getMatchDetail(m.id).match,
    deltaLp: m.playerMatch?.playerMatchStats?.deltaLp || 0,
  }));

  return (
    <>
      <div className="event-card-ad-target display-contents" />
      <MatchTileHeader
        champions={champions}
        role={role}
        currentAccount={profile}
        matches={loadedMatches}
      />
      <ProfileMatchlist>
        <SharedMatchList matchList={mergedMatchlist}>
          <SeasonReviewPromo region={region} name={name} profile={profile} />
          {filteredMatchlist.map((matchlistItem, i) => {
            const { match } = getMatchDetail(matchlistItem.id);

            return (
              <React.Fragment key={`${i}-${matchlistItem.id}`}>
                <SharedMatchTile
                  id={matchlistItem.id}
                  height={142}
                  match={match}
                >
                  <LoLMatchTile
                    matchId={match?.gameId}
                    match={match}
                    region={region}
                    name={name}
                    matchLinkPrefix={matchLinkPrefix}
                    profile={profile}
                    lp={matchlistItem?.playerMatch?.playerMatchStats?.deltaLp}
                  />
                </SharedMatchTile>
              </React.Fragment>
            );
          })}
        </SharedMatchList>
      </ProfileMatchlist>
    </>
  );
}

function SeasonReviewPromo({ region, name, profile }) {
  const [promo, setPromo] = useState(null);

  useEffect(() => {
    (async () => {
      if (!shouldShowPromo()) return;
      const e = import("@/game-lol/components/ProfileSeasonReviewPromo.jsx");
      if (!e) return;
      setPromo(await e);
    })();
  }, []);

  if (!promo) return null;

  return React.createElement(promo.default, { region, name, profile });
}

export function meta([regionId, name, _tab, _roleStr, _champion]) {
  const region = REGION_LIST.find((region) => region.key === regionId);
  const userName = name.replace("-", " #");

  return {
    title: [
      "lol:meta.profile.title",
      "{{userName}}'s {{regionName}} League of Legends Performance Overview",
      { userName, regionName: region?.name ?? regionId },
    ],
    description: [
      "lol:meta.profile.description",
      "View {{userName}}'s League of Legends profile and see how they perform.",
      { userName, regionName: region?.name ?? regionId },
    ],
  };
}

export default Profile;
